import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSessionContext } from '@supabase/auth-helpers-react';
import { supabase } from '../lib/supabaseClient';

export const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
  const [usuario, setUsuario] = useState(null);
  const { session } = useSessionContext();

  useEffect(() => {
    if (session?.user?.email) {
      fetchUserByEmail(session.user.email);
    }
  }, [session]);

  const fetchUserByEmail = async (email) => {
    const { data, error } = await supabase
      .from('usuarios')
      .select('*')
      .eq('email', email)
      .single();

    if (error) {
      console.error('Error fetching user:', error);
    } else {
      setUsuario(data);
    }
  };

  return (
    <UserContext.Provider value={{ usuario, setUsuario }}>
      {children}
    </UserContext.Provider>
  );
};


