import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '@supabase/auth-helpers-react';

const ProtectedRoute = ({ children }) => {
  const userContext = useUser();
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (userContext !== undefined) {
      setLoading(false);
    }
  }, [userContext]);

  useEffect(() => {
    if (!userContext) {
      const timer = setTimeout(() => {
        setShouldRedirect(true);
      }, 1000); // Espera 3 segundos antes de redireccionar
      return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta
    } else {
      setShouldRedirect(false); // Resetea el estado si userContext cambia
    }
  }, [userContext]);

  if (!userContext && shouldRedirect) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return <div>Cargando...</div>; // O cualquier indicador de carga
  }

  return children;
};

export default ProtectedRoute;