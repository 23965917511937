import React, { useEffect } from 'react';
import { supabase } from './../lib/supabaseClient'; // Cambiar la ruta aquí

const Logout = () => {
  useEffect(() => {
    const handleLogout = async () => {
      if (supabase) { // Verifica que supabase no sea undefined
        await supabase.auth.signOut();
        window.location.href = '/'; // Redirigir a la página de inicio después de cerrar sesión
      } else {
        console.error("Supabase no está definido");
      }
    };

    handleLogout();
  }, []);

  return (
    <div>
      <h2>Cerrando sesión...</h2>
    </div>
  );
};

export default Logout;