// Divider.jsx
import React, { useEffect, useRef } from 'react';
import './Divider.css';

const Divider = ({ onDrag }) => {
  const dividerRef = useRef(null);

  useEffect(() => {
    const divider = dividerRef.current;
    let startX, startWidthLeftPane;

    const startDrag = (e) => {
      startX = e.clientX;
      startWidthLeftPane = divider.previousElementSibling.offsetWidth;
      document.addEventListener('mousemove', onDragMove);
      document.addEventListener('mouseup', stopDrag);
    };

    const onDragMove = (e) => {
      const currentX = e.clientX;
      const newWidth = startWidthLeftPane + (currentX - startX);
      if (onDrag && typeof onDrag === 'function') {
        onDrag(newWidth);
      }
    };

    const stopDrag = () => {
      document.removeEventListener('mousemove', onDragMove);
      document.removeEventListener('mouseup', stopDrag);
    };

    divider.addEventListener('mousedown', startDrag);

    return () => {
      divider.removeEventListener('mousedown', startDrag);
    };
  }, [onDrag]);

  return <div className="divider" ref={dividerRef}></div>;
};

export default Divider;
