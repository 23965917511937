// src/utils/supabaseClient.js

import { createClient } from '@supabase/supabase-js';

// Cambia process.env a import.meta.env
const supabaseUrl = import.meta.env.VITE_SB_URL;
const supabaseKey = import.meta.env.VITE_SB_KEY;
const serviceRoleKey = import.meta.env.VITE_SB_SERVICE_ROLE_KEY; // Añade esta línea

if (!supabaseUrl || !supabaseKey || !serviceRoleKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseKey);
export const supabaseAdmin = createClient(supabaseUrl, serviceRoleKey); // Cliente con permisos de administrador
