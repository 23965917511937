import React, { useState, useEffect } from 'react';
import { useUser } from "@supabase/auth-helpers-react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useNavigate } from 'react-router-dom';
import './Home.css';
import { Link } from 'react-router-dom';
import Footer from '../objetos/Footer';

function useMobileCheck() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
}

const Home = () => {
  const isMobile = useMobileCheck();
  const user = useUser();
  const supabaseClient = useSupabaseClient();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notification, setNotification] = useState({ show: false, message: '', type: '' });
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      setImagePosition({ x: clientX * 0.05, y: clientY * 0.05 }); // Ajusta la sensibilidad aquí
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     navigate('/dashboard/');
  //   }
  // }, [user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("Intentando iniciar sesión con:", email, password);
    const { error } = await supabaseClient.auth.signInWithPassword({ email, password });
    if (error) {
      console.error("Error al iniciar sesión:", error);
      let translatedMessage = '';
      switch (error.message) {
        case 'Invalid login credentials':
          translatedMessage = 'Credenciales Incorrectas';
          break;
        case 'Password should be at least 6 characters.':
          translatedMessage = 'Contraseña demasiado corta.';
          break;
        case 'User not found':
          translatedMessage = 'Usuario no encontrado.';
          break;
        default:
          translatedMessage = 'Error al iniciar sesión';
      }
      setNotification({ show: true, message: translatedMessage, type: 'error' });
    } else {
      navigate('/dashboard/');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin(e);
    }
  };

  return (
    <div className="container">
      <div className="flex-1 flex justify-center items-center bg-mies">
        <Link to="/dashboard/">
          <img className="logo-mies" src="/img/logo-mies.png" alt="MIES" />
        </Link>
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center w-full bg-mies"> {/* Cambiado a flex-col para móviles y flex-row para pantallas medianas y grandes */}
        <div className="w-full md:w-3/5 flex justify-center items-center img-capacitacion-container"> {/* Asegurado que use el 100% en móviles y 3/5 en PC */}
          <div className="img-capacitacion-wrapper">
            <img src="/img/icon-flechitas.png" alt="Nuevo Icono" className="icon-flechitas" />
            <img 
              src="/img/capacitacion-vr.png" 
              alt="VR Training" 
              className={`img-capacitacion ${isMobile ? '' : 'img-capacitacion-pc'}`} // Añadido clase condicional
              style={{ transform: `translate(${imagePosition.x * 0.1}px, ${imagePosition.y * 0.1}px)` }} // Ajustada la sensibilidad
            />
          </div>
        </div>

        <div className="w-full md:w-2/5 p-4 flex flex-col justify-start"> {/* Asegurado que use el 100% en móviles y 2/5 en PC */}
          <div className="flex flex-col justify-start w-full"> {/* Asegurado que use el 100% del ancho */}
            <h1 className="titulo-mies text-xl md:text-4xl"> {/* Ajuste del tamaño de la fuente */}
              Plataforma de Reportes <br />
              Capacitaciones 360°/VR <br />
              Riesgos Críticos. <br />
              MIES VR
            </h1>
            <p className="subtitulo-mies mt-2">Rápido Acceso a datos y reportes</p>
          </div>

          <div className="flex flex-col justify-start w-full mt-10">
            <div className="input-container">
              <label htmlFor="usuario" className="input-label">Usuario:</label>
              <div className="input-wrapper">
                <img src="/img/icon-usuario.png" alt="Usuario" className="input-icon" />
                <input type="text" id="usuario" placeholder="mail@mail.cl" className="input-field" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
            </div>
            <div className="input-container mt-4">
              <label htmlFor="contrasena" className="input-label">Contraseña:</label>
              <div className="input-wrapper">
                <img src="/img/icon-contraseña.png" alt="Contraseña" className="input-icon" />
                <input 
                  type="password" 
                  id="contrasena" 
                  placeholder="contraseña" 
                  className="input-field" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              {notification.show && notification.type === 'error' && (
                <div className="error-message">
                  <span>{notification.message}</span>
                </div>
              )}
            </div>
            <br />
            <div className="button-container"> 
              <button className="btn-entrar" onClick={handleLogin}>Entrar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="features-container bg-mies pt-20 flex flex-col md:flex-row"> {/* Cambiado para que se adapten en vertical en móviles */}
        <div className="feature mb-4 md:mb-0"> {/* Añadido margen inferior para separación en móviles */}
          <img src="/img/icon-monitoreo.png" alt="Monitoreo en tiempo real" className="feature-icon" />
          <h2 className="feature-title text-justify">Monitoreo en tiempo real</h2>
          <p className="feature-description text-justify">Lleva un control real del conocimiento de tu equipo</p>
        </div>
        <div className="feature mb-4 md:mb-0"> {/* Añadido margen inferior para separación en móviles */}
          <img src="/img/icon-reportes.png" alt="Rápido acceso a Reportes" className="feature-icon" />
          <h2 className="feature-title text-justify">Rápido acceso a Reportes</h2>
          <p className="feature-description text-justify">Datos específicos de los riesgos críticos</p>
        </div>
        <div className="feature mb-4 md:mb-0"> {/* Añadido margen inferior para separación en móviles */}
          <img src="/img/icon-estadisticas.png" alt="Estadísticas precisas" className="feature-icon" />
          <h2 className="feature-title text-justify">Estadísticas precisas</h2>
          <p className="feature-description text-justify">Crea nuevos usuarios, personaliza sus opciones y visualiza sus proyectos.</p>
        </div>
      </div>

      <div className="footer-info bg-mies pt-20">
        <h2 className="text-white">Servicios industriales con calidad alemana</h2>
        <div className="footer-logos flex justify-center items-center pt-10 pb-10">
          <img src="/img/logo_mies-blanco.png" alt="MIES" className="logo-mies-footer" />
        </div>
        <div className="footer-text text-white">
          <p className="text-sm">Av. Freire 1320, Barrio Industrial</p>
          <p className="text-sm">El Belloto, Quilpué/Chile</p>
        </div>
        <br />
        <div className="footer-tuvd flex flex-col items-center justify-center p-10 bg-white"> {/* Cambiado para centrar el contenido */}
          <a href="https://tuvid.cl" target="_blank" rel="noopener noreferrer" className="text-white mt-2">
          <span className="text-mies">
              © {new Date().getFullYear()} by Tuvid Technologies. All rights reserved.
          </span>
          </a>
        </div>
      </div>

    </div>
  );
};

export default Home;
