import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { useUserContext } from './UserContext';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { usuario } = useUserContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Ajusta el tamaño según tus necesidades

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {isMobile && ( 
        <List>
          <ListItem disablePadding>
            <ListItemText primary={usuario ? `${usuario.nombre} ${usuario.apellido}` : 'Usuario no autenticado'} align="center" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary={usuario ? usuario.email : ''} align="center" />
          </ListItem>
        </List>
      )}
      <Divider /> 
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/dashboard')}>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>
      </List>

      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/salir')}>
            <ListItemText primary="Salir" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
    </Box>
  );

  return (
    <>
      <div className="header-content bg-mies text-white d-flex align-items-center" style={{ userSelect: 'none' }}>
        <div className="logo-container" style={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard')}>
          <img className="logo-mies-dashboard" src="/img/logo-mies.png" alt="Logo Mies" />
        </div>
        <div className="header-text text-center m-20" style={{ cursor: 'default' }}>
          <h2>Inducción 360° / VR / Multiplayer</h2>
          <p>Riesgos críticos</p>
        </div>
        <div className="logo-container mt-10">
          <div className="user-info d-flex align-items-center justify-content-between" style={{ cursor: 'default' }}>
            <div className="user-details">
              <p className="user-name">{usuario ? `${usuario.nombre} ${usuario.apellido}` : 'Usuario no autenticado'}</p>
              <p className="user-email">{usuario ? usuario.email : ''}</p>
            </div>
            <span 
              className="material-symbols-outlined ml-5 cursor-pointer mr-8" 
              onClick={toggleDrawer(true)}
            >
              menu
            </span>
          </div>
        </div>
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    </>
  );
};

export default Header;