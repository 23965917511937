import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useTheme } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import html2pdf from 'html2pdf.js';
import RC09Certificado from './RC09Certificado';
import './VistaResultado.css';

const VistaResultado = ({ userId: propUserId, intento: propIntento, showNavBar = true, isFromDashboard = false }) => {
  const theme = useTheme();
  const { userId: paramUserId, intento: paramIntento } = useParams();
  const location = useLocation();
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const supabaseClient = useSupabaseClient();
  const navigate = useNavigate();

  // Usa los props si están disponibles, de lo contrario usa los parámetros de la URL
  const userId = propUserId || paramUserId;
  const intento = propIntento || paramIntento;
  const showNavBarFromState = location.state?.showNavBar ?? showNavBar;

  // Calcular puntaje y puntajeFinal solo si selectedUser no es null
  const puntajeFinal = selectedUser ? Math.floor(parseFloat(selectedUser.porcentaje.replace(',', '.'))) === 99 ? 100 : Math.floor(parseFloat(selectedUser.porcentaje.replace(',', '.'))) : 0; // Si no hay usuario, puntajeFinal es 0

  const handleDownloadPDF = async () => {
    console.log('Generando PDF...');
    const element = document.createElement('div');
    const root = createRoot(element);
    
    // Asegúrate de que selectedUser tenga datos antes de pasar
    if (!selectedUser) {
      console.error('No hay usuario seleccionado');
      return; // Salir si no hay usuario seleccionado
    }

    // Renderiza el componente y pasa selectedUser y el nombre de la tabla
    root.render(
      <div style={{ backgroundColor: 'white', color: 'black', padding: '20px' }}>
        <RC09Certificado selectedUser={selectedUser} />
      </div>
    );

    console.log(element.innerHTML); // Verifica el contenido

    setTimeout(async () => {
      try {
        await html2pdf()
          .from(element)
          .save(`RC09-Certificado-${selectedUser.email}.pdf`); // Incluye el email del usuario en el nombre del archivo
      } catch (err) {
        console.error('Error al generar el PDF:', err);
      }
    }, 100);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      if (!userId || !intento) {
        console.error('userId o intento no proporcionados');
        setIsLoading(false);
        return;
      }

      try {
        const { data, error } = await supabaseClient
          .from('graduados')
          .select('*')
          .eq('idUser', userId)
          .eq('intento', intento)
          .single();

        if (error) throw error;
        setSelectedUser(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [userId, intento, supabaseClient]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (!selectedUser) {
    return <div>No se encontraron datos para este usuario e intento.</div>;
  }

  const questions = [
    'Catridge de Lubricación',
    'Piola de Seguridad',
    'Polín Defectuoso'
  ];

  const logroCatridgeBienURL    = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Correcto-catridge-de-lubricacion.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvQ29ycmVjdG8tY2F0cmlkZ2UtZGUtbHVicmljYWNpb24uanBnIiwiaWF0IjoxNzE1NTcwNTQ3LCJleHAiOjE3NDcxMDY1NDd9.1rDymzOOchPCVS7-sXGMypGCswt2yvRTCzbv_MA13ZI&t=2024-05-13T03%3A22%3A27.827Z';
  const logroCatridgeMalURL     = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Incorrecto-catridge-de-lubricacion.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvSW5jb3JyZWN0by1jYXRyaWRnZS1kZS1sdWJyaWNhY2lvbi5qcGciLCJpYXQiOjE3MTU1NzA1NjIsImV4cCI6MTc0NzEwNjU2Mn0.ZnC7MpphlK9-Ayz7t_K3Tm4v_IGBcJQzOIPmd7RgHWA&t=2024-05-13T03%3A22%3A42.688Z';
  const logroProteccionBienURL  = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Correcto-barrera-de-seguridad.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvQ29ycmVjdG8tYmFycmVyYS1kZS1zZWd1cmlkYWQuanBnIiwiaWF0IjoxNzE1NTcwNTc3LCJleHAiOjE3NDcxMDY1Nzd9.jp9VwzVNYaB7Z3IjcNc995uHOACtRdOyrasMDQngXaE&t=2024-05-13T03%3A22%3A57.676Z';
  const logroProteccionMalURL   = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Incorrecto-barrera-de-proteccion.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvSW5jb3JyZWN0by1iYXJyZXJhLWRlLXByb3RlY2Npb24uanBnIiwiaWF0IjoxNzE1NTcwNjI3LCJleHAiOjE3NDcxMDY2Mjd9.D6pMSpzmIZeMw-mZgzDUHhE0CX4rAs2QQ-RAXt1eDTM&t=2024-05-13T03%3A23%3A47.258Z';
  const logroPolinBienURL       = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Correcto-polin-defectuoso.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvQ29ycmVjdG8tcG9saW4tZGVmZWN0dW9zby5qcGciLCJpYXQiOjE3MTU2MDM5MjcsImV4cCI6MTc0NzEzOTkyN30.q1jw337ryyMrehbnlqWEthpLmZk5_nG2zwjUopq80b4&t=2024-05-13T12%3A38%3A47.929Z';
  const logroPolinMalURL        = 'https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Incorrecto-Polin-defectuoso.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvSW5jb3JyZWN0by1Qb2xpbi1kZWZlY3R1b3NvLmpwZyIsImlhdCI6MTcxNTU3MDY3NiwiZXhwIjoxNzQ3MTA2Njc2fQ.jbi4IYsvNzxrCEpVLh_D7opUBb49WBYeTHD8EuMwZAU&t=2024-05-13T03%3A24%3A36.516Z';

  const questionScreenshots = [
    { number: 1, correct: logroCatridgeBienURL, incorrect: logroCatridgeMalURL },
    { number: 2, correct: logroProteccionBienURL, incorrect: logroProteccionMalURL },
    { number: 3, correct: logroPolinBienURL, incorrect: logroPolinMalURL }
  ];

  const getBadgeStyle = (isCorrect) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 8px',
    borderRadius: '20px',
    fontSize: '0.875rem',
    lineHeight: '1',
    fontWeight: '500',
    color: 'white',
    backgroundColor: isCorrect ? '#10B981' : '#EF4444', // verde para correcto, rojo para incorrecto
  });

  return (
    <div 
      className={`no-select ${showNavBarFromState ? 'flex justify-center' : ''} min-h-screen bg-gray-100`}
      tabIndex="-1"
    >
      <div 
        className={`no-select no-focus ${showNavBarFromState ? 'max-w-[900px]' : 'w-full'} px-4 py-8 overflow-y-auto`}
        tabIndex="-1"
      >
        {/* Encabezado */}
        <div className={`flex items-center mb-8 ${theme.palette.mode === 'dark' ? 'text-gray-200' : 'text-gray-800'}`}>
          <img src="https://ctnhximzoqijdhdunfhn.supabase.co/storage/v1/object/public/mies/Portadas/RC09.png" alt="Portada del curso" className="w-20 h-20 mr-4" />
          <h1 className="text-2xl font-bold titulo-curso">Atrapamiento / Aprisionamiento con partes móviles</h1>
        </div>
        
        {/* Mostrar el botón de volver solo si no es desde el Dashboard */}
        {!isFromDashboard && ( // Condición para mostrar el botón
          <span className="material-symbols-outlined">
            <span className="btn-volver" onClick={() => navigate(-1)}>
              reply
            </span>
          </span>
        )}
        
        {/* Información del usuario y botón de descarga */}
        <div className="flex justify-between items-start mb-8">
          <div className="flex flex-col datos-usuario">
            <p className="font-bold">
              <a href={`/usuario-perfil/${selectedUser?.idUser}`} className="text-blue-500 underline">
                {selectedUser?.email?.toLowerCase() || ''}
              </a>
            </p>
            <p>Curso: {selectedUser?.idCurso || ''}</p>
            <p>Modulo: {selectedUser?.modulo || ''}</p>
            <p>Puntaje: {selectedUser?.puntaje || ''}</p>
            <p>Código: {selectedUser?.intento || ''}</p>
          </div>

          <div className="flex flex-col items-center">
            {/* Span para mostrar aprobado o reprobado */}
            <p className={`${puntajeFinal >= 99 ? 'usuario-aprobado' : 'usuario-reprobado'}`}>
              {puntajeFinal >= 99 ? 'Aprobado' : 'Reprobado'}
            </p>
            {/* Puntaje */}
            <p className='cert-puntaje'><span>{puntajeFinal}%</span></p>

            {/* Botón de descarga */}
            <button 
              className="btn-download"
              onClick={handleDownloadPDF}
            >
              <span className="material-symbols-outlined mr-2">
                download
              </span>
              Descargar Informe
            </button>
          </div>
        </div>

        {/* Declaraciones de entendimiento */}
        <div className="mb-20">
          <div className="bg-white shadow-md rounded-lg p-4">
            <h2 className="text-xl font-bold mb-4 mt-4 text-center">DECLARACIONES DE ENTENDIMIENTO</h2>
            <div className="flex flex-col items-center space-y-4">
              {/* Cambiar a flex-row para que las imágenes aparezcan una al lado de la otra */}
              <div className="flex flex-row space-x-4">
                <img
                  src="https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/1-informacion-importante-de-ingreso-al-area.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvMS1pbmZvcm1hY2lvbi1pbXBvcnRhbnRlLWRlLWluZ3Jlc28tYWwtYXJlYS5qcGciLCJpYXQiOjE3Mjg1NzM1MjksImV4cCI6MjA0MzkzMzUyOX0.i3N5s1amMUwhehfX4QCzT0VpKyvwU5OOwKxP3aU6I_g&t=2024-10-10T15%3A18%3A50.475Z"
                  alt="Declaración entendimiento puntos críticos"
                  className="w-full max-w-[300px] mt-10"
                />
                <img
                  src="https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/2-Declaracion-entendimiento-puntos-criticos.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvMi1EZWNsYXJhY2lvbi1lbnRlbmRpbWllbnRvLXB1bnRvcy1jcml0aWNvcy5qcGciLCJpYXQiOjE3MTU1NzM1MDQsImV4cCI6MTc0NzEwOTUwNH0.Q1vUFjCpo21zNNV11uaYyihsvbDooF8_wCSN_w_vXqM&t=2024-05-13T04%3A11%3A44.475Z"
                  alt="Declaración entendimiento puntos críticos"
                  className="w-full max-w-[300px] mt-10"
                />
              </div>
            </div>
            <div className="flex flex-col items-center space-y-4">
              <div className="flex flex-row space-x-4">
                <img
                  src="https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/3-advertencia-riesgos-principales.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvMy1hZHZlcnRlbmNpYS1yaWVzZ29zLXByaW5jaXBhbGVzLmpwZyIsImlhdCI6MTcyODU3MzU5MywiZXhwIjoyMDQzOTMzNTkzfQ.ywHysJBmww8QVSul804NMbmzyi3PN4piLTJHUHlJeyM&t=2024-10-10T15%3A19%3A54.254Z"
                  alt="Declaración entendimiento puntos críticos"
                  className="w-full max-w-[300px] mt-10"
                />
                <img
                  src="https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/4-conocimiento-de-piola-de-seguridad.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvNC1jb25vY2ltaWVudG8tZGUtcGlvbGEtZGUtc2VndXJpZGFkLmpwZyIsImlhdCI6MTcyODU3MzYxNywiZXhwIjoyMDQzOTMzNjE3fQ.UEmZdpN_IIMaekzseBopfEfkGZOo6vtA5OCbYprFGPU&t=2024-10-10T15%3A20%3A17.545Z"
                  alt="Declaración entendimiento puntos críticos"
                  className="w-full max-w-[300px] mt-10"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Preguntas y respuestas */}
        <div className="mb-20">
          <h2 className="text-xl font-bold mb-4 text-center">PREGUNTAS</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {questions.map((questionText, index) => {
              const questionNumber = index + 1;
              const questionKey = `resultadopregunta${questionNumber}`;
              const isCorrect = selectedUser[questionKey] === 'correcta';
              const screenshot = questionScreenshots.find(s => s.number === questionNumber);
              const screenshotFilename = isCorrect ? screenshot.correct : screenshot.incorrect;
              const badgeText = isCorrect ? 'Correcta' : 'Incorrecta';

              return (
                <div className="bg-white shadow-md rounded-lg overflow-hidden" key={questionNumber}>
                  <img src={screenshotFilename} alt={`Respuesta ${badgeText}`} className="w-full h-48 object-cover" />
                  <div className="p-4">
                    <p className="text-sm text-gray-600">Pregunta {questionNumber}</p>
                    <h3 className="font-bold mt-1">{questionText.toUpperCase()}</h3>
                    <div style={getBadgeStyle(isCorrect)}>
                      {badgeText}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Zonas prohibidas */}
        <div className="mb-20">
          <div className="bg-white shadow-md rounded p-4">
            <h2 className="text-xl font-bold mb-4 text-center col-span-full">ZONAS PROHIBIDAS</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {selectedUser.zonaprohibida1 === 'si' && (
                <div className="bg-white shadow-md rounded-lg overflow-hidden">
                  <img 
                    src="https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Zona-prohibida-1.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNo/RC09/Zona-prohibida-1.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvWm9uYS1wcm9oaWJpZGEtMS5qcGciLCJpYXQiOjE3MTU1NzQxNzIsImV4cCI6MTc0NzExMDE3Mn0.QptNEp4Ku33-_rFyrW32vZS7NcbF8c01ObN7xGux6Ek&t=2024-05-13T04%3A22%3A52.675Z" 
                    alt="Infracción Zona 1"
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h3 className="font-bold">Ingresó a Zona Prohibida 1</h3>
                  </div>
                </div>
              )}
              {selectedUser.zonaprohibida2 === 'si' && (
                <div className="flex flex-col justify-center items-center mt-10">
                  <p className="text-lg font-bold mb-2">Ingresó a Zona Prohibida 2</p>
                  <img src="https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Zona-prohibida-2.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvWm9uYS1wcm9oaWJpZGEtMi5qcGciLCJpYXQiOjE3MTU1NzQxODYsImV4cCI6MTc0NzExMDE4Nn0.BTVmoxiRusyg4u3KqY6UkluBKyirurhwiCZC8fSkS-8&t=2024-05-13T04%3A23%3A06.174Z" 
                  alt="Infracción Zona 2"
                  style={{ maxWidth: '300px', width: '100%', textAlign: 'center', margin: '0 auto' }}
                  />
                </div>
              )}
              {selectedUser.zonaprohibida3 === 'si' && (
                <div className="flex flex-col justify-center items-center mt-10">
                  <p className="text-lg font-bold mb-2">Ingresó a Zona Prohibida 3</p>
                  <img src="https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Zona-prohibida-3.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvWm9uYS1wcm9oaWJpZGEtMy5qcGciLCJpYXQiOjE3MTU1NzQxOTksImV4cCI6MTc0NzExMDE5OX0.CCrwhMgAjwg-YGc7-ATr37NGf4zhPZS7WdHEt_zoa0k&t=2024-05-13T04%3A23%3A19.760Z" 
                  alt="Infracción Zona 3" 
                  style={{ maxWidth: '300px', width: '100%', textAlign: 'center', margin: '0 auto' }}
                  />
                </div>
              )}
              {selectedUser.zonaprohibida1 !== 'si' && selectedUser.zonaprohibida2 !== 'si' && selectedUser.zonaprohibida3 !== 'si' && (
                <div className="col-span-full flex flex-col items-center justify-center">
                  <span className="material-symbols-outlined text-green-500 text-9xl mb-4">
                    task_alt
                  </span>
                  <p className="text-lg font-bold text-center">No entró a ninguna zona prohibida</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VistaResultado;