import React from 'react';
import './RC09Certificado.css';



const RC09Certificado = ({ selectedUser }) => { // Asegúrate de recibir selectedUser como prop
  // const currentDate = new Date();
  const questions = [
    { pregunta: 'Pregunta 1:', text: 'Catridge de Lubricación', approved: selectedUser.resultadopregunta1 === 'correcta' },
    { pregunta: 'Pregunta 2:', text: 'Piola de Seguridad', approved: selectedUser.resultadopregunta2 === 'correcta' },
    { pregunta: 'Pregunta 3:', text: 'Polín Defectuoso', approved: selectedUser.resultadopregunta3 === 'correcta' }
  ];
  
  // Completar email y fechas
  const email = selectedUser.email;
  const formattedDate = new Date(selectedUser.termino).toLocaleDateString('es-ES'); // Formato de fecha
  const formattedTime = new Date(selectedUser.termino).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  // const formattedDate = currentDate.toLocaleDateString('es-ES'); // Formato de fecha
  // const formattedTime = currentDate.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }); // Formato de hora

  // Asegúrate de que selectedUser.porcentaje sea un número
  const puntaje = Math.floor(parseFloat(selectedUser.porcentaje.replace(',', '.'))); // Convertir porcentaje a número
  const puntajeFinal = puntaje === 99 ? 100 : puntaje; // Si el puntaje es 99, establecerlo en 100

  return (
    <div className='certificado-container'>
      <h1 className='cert-titulo'>Plataforma MIES VR</h1>
      <p className={`${puntajeFinal >= 99 ? 'cert-aprobado' : 'cert-reprobado'}`}>
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p className='cert-curso'><span>Curso:</span> Atrapamiento / Aprisionamiento con partes móviles</p>
        <p className='cert-puntaje'>Puntaje:<span>{puntajeFinal}%</span></p>
      </div>
      
      <p className='cert-email'>{email}</p>
      <div className='cert-questions'>
        {questions.map((question, index) => (
          <div key={index} className='cert-question'>
            <div className='question-content'>
              <p className='question-title'>Pregunta {index + 1}</p>
              <p className='question-text'>{question.text}</p>
            </div>
            <div className='question-status'>
              {question.approved ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="11" fill="#7aac4b"></circle>
                  <path fill="none" stroke="white" strokeWidth="2.5" d="M7 12l3 3l7-7"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="11" fill="#c3322d"></circle>
                  <path fill="none" stroke="white" strokeWidth="2" d="M8,8l8,8m0-8l-8,8"></path>
                </svg>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className='footer'>
        <div className='footer-item'>
          <span className="material-symbols-outlined">event_note</span>
          Fecha: {formattedDate}
        </div>
        <div className='footer-item'>
          <span className="material-symbols-outlined">schedule</span>
          Hora: {formattedTime}
        </div>
      </div>

    </div>
  );
};

export default RC09Certificado;