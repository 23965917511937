import React from 'react';
import './Footer.css';

const Footer = () => {

  return (
    <footer className="footer-mies mt-40">
      <aside>
        <a href="https://www.mies.cl/" target='_blank'>
          <img src='/img/logo-mies-footer.png' alt="logo Mies" />
        </a>
      </aside> 
      
    </footer>
  );
};

export default Footer;
